<template>
  <div>
  <!-- 标签部分 -->

 <el-row type="flex" align="middle"  v-for="(item,i ) in policy" :key="item.id">
  <el-col :span="1"  >标签{{i+1}}  :</el-col>
  <el-col :span="4" align="middle">
    <input disabled :id="item.id" :class="ipt" v-model="item.title" placeholder="最多四字" />
  </el-col>
  <el-col :span="2" align="middle">
    <button class="editBtn" @click="edit(item.id)">编辑</button>
  </el-col>
  <!-- <el-col :span="2">
    <el-button  :class="isHide" @click="changeBtn">{{hideLabel}}</el-button>
  </el-col> -->
 </el-row>


 
  </div>
</template>

<script>
export default {
 props: {
  policy:Array
 },
  data() {
    return {
      // hideLabel:'隐藏该标签',
      // flag:true,
      // isHide:'hide',
      ipt:'ipt',
    }
  },
  created () {
    
  },
methods: {
  // 隐藏标签点击事件
  // changeBtn(){
  //   if(this.flag){
  //  this.hideLabel='解除隐藏'
  //  this.isHide='dishide'
  //  this.ipt='iptt'
  //    this.flag=false
  //   }else{
  //  this.isHide='hide'
  //  this.hideLabel='隐藏该标签'
  //  this.ipt='ipt'

  //   this.flag=true
  //   }
  // },
  //编辑按钮操作
 edit(id){
      this.$emit('showDialog',id)
      console.log(123);
 },
 
}
}
</script>

<style lang="less" scoped>
  .el-row{
    margin-top: 30px;
    margin-left: 10px;
    margin-bottom: 76px;
    .el-col:nth-child(1){
      font-size: 14px;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
color: #4A71AF;
margin-right: 16px;

    }
    .el-col:nth-child(2)  .ipt{
  width: 248px;
height: 40px;
background: #FFFFFF;
border-radius: 12px;
border: 1px solid #C9C9C9;
padding-left: 20px;
font-size: 14px;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
color: #292D31;
}
 .el-col:nth-child(2)  .iptt{
  width: 248px;
height: 40px;
background: #FFFFFF;
border-radius: 12px;
border: 1px solid #C9C9C9;
padding-left: 20px;
font-size: 14px;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
color: #9F9F9F ;
}

.editBtn {
border: 0;
 color: #fff;

border-radius: 20px;
 width: 110px;
height: 40px;
background: #4A71AF;
}
.hide{
  color:  #4A71AF;
  width: 130px;
height: 40px;
background: #FFFFFF;
border-radius: 12px;
border: 2px solid #4A71AF;
}
.dishide{
  width: 130px;
height: 40px;
background: #EDF1F7;
border:0;
border-radius: 12px;
}
  }




</style>