<template>
  <div>
          <!-- 头部位置 -->
  <el-row  class="header" type="flex" justify="start" align="middle">
    <el-col :span="2">/新闻管理/标签设置</el-col>
  </el-row>
  <!-- 标签位置 -->
    <Label :policy="policy" @showDialog="showDialog"></Label>
  <!-- 弹框 -->
  <el-dialog title="标签编辑"  :visible.sync="isShow" @close="closeDia">
  <!-- 表单 -->
   <el-form :model="newtitle" :rules="rules" ref="editForm" label-width="80px" >
  <el-form-item label="原标签:" >
    <el-input disabled v-model="title" class="disable" ></el-input>
  </el-form-item>
  <el-form-item label="新标签:"  prop="tile" >
    <el-input v-model="newtitle.tile" placeholder="最多四字"></el-input>
  </el-form-item>
  <!-- 确定和取消 -->
  <el-form-item class="subbtn">
    <el-button class="btnOK"  @click="btnok"
     >确认</el-button>
    <el-button @click="btnout">取消退出</el-button>
  </el-form-item>
</el-form>
  </el-dialog>
  </div>
</template>

<script>
import Label from './label.vue'
import {getTile,editTitle} from '@/api/news.js'

export default {
components: {
  Label
},
data() {
   var checktile=(rule,value,callback)=>{
  let flag= this.policy.some(item=>item.title===value)
  if(flag){
     callback(new Error('新闻标签不能重复'))
  }else{
    callback()
  }
  };
  return {
 
    isShow:false,
      policy:[],
   
      title:'',
      newtitle:{
        tile:'',
           id:''+1,
      },
      rules:{
tile:[{ required: true, message: '新标签不能为空', trigger: 'blur' },
 { min: 1, max: 4, message: '最多四字', trigger: 'blur' },
 {validator: checktile,trigger:'blur'}
 ],

      }
  }
},
created () {
    this.getTile()
  
},
methods: {
     //标签类型获取
   async getTile(){
    let {data}= await getTile()
    console.log(data);
    this.policy=data
  },
  //弹窗的显示
  showDialog(id){

   this.newtitle.id=id
   let num=this.policy[this.newtitle.id-1]
   this.title=num.title
   this.isShow=true

   },
  //点击确定按钮
     async btnok(){
       await this.$refs.editForm.validate()
       await editTitle(this.newtitle)
       this.getTile()
      this.closeDia()
       this.$message.success('修改成功')
      },
          //点击×按钮退出
  closeDia(){
    this.isShow=false
      this.$refs.editForm.resetFields()
     this.newtitle.tile=''
     
  },
      //点击取消按钮
    btnout(){

      this.closeDia()
    },

}
}
</script>

<style lang="less" scoped>
.subbtn{
 
 padding-right: 40px;
  margin-top: 70px;
}
.el-button{
width: 110px;
height: 40px;
background: #FFFFFF;
border-radius: 12px;
border: 2px solid #4A71AF;

font-size: 14px;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
color: #4A71AF;
line-height: 15
px;
}
.btnOK&.el-button {
width: 110px;
height: 40px;
background: #4A71AF!important;
border-radius: 12px;



font-size: 14px;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
color: #FFFFFF;
margin-right: 60px;
}
/deep/ .el-input{
  width: 60%;
  margin-right:90px;
   .el-input__inner{
   width: 300px;
height: 40px;
border-radius: 12px;
border: 2px solid #4A71AF;
  }
}
.disable /deep/ .el-input__inner{
  width: 300px;
height: 40px;
background: #F2F2F2;
border-radius: 12px;
border: 2px solid #E1E1E1;
}
/deep/ .el-form .el-form-item__label{
  margin-left: 100px;
  width: 60px;
height: 22px;
font-size: 14px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #273352;
line-height: 22px;
letter-spacing: 1px;
 margin-top: 6px;

}
/deep/ .el-form .el-form-item{
  margin-bottom: 30px;
}

 /deep/ .el-dialog{
  width: 600px;
height: 380px;
background: #FFFFFF;
border-radius: 51px;
padding-top: 15px;
}
/deep/ .el-dialog__title{
  width: 76px;
height: 22px;
font-size: 19px;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
color: #273352;
line-height: 22px;
}
/deep/ .el-dialog__close{
font-size: 18px;
color: #333303;
opacity: 0.4;
margin-top: 18px;
margin-right: 20px;
}
.header{
 height: 45px;
background: #F9F9F9;
 .el-col{
    font-size: 12px;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
color: #4A71AF;
  }
}
</style>