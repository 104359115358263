import request  from "../utils/request";
//获取和筛选新闻列表
export const getList=data=>{
return request({
  method:'POST',
  url:'/system/news/getNewsList',
  data

})
}
//获取新闻类型
export const getTile=data=>{
  return request({
    method:'POST',
    url:'/system/news/getTile',
    data
  
  })
  }
  //修改标签标题
  
  export const editTitle=data=>{
    return request({
      method:'POST',
      url:'/system/news/updateTile',
      data
    
    })
    }
    //新增新闻
    
    export const addNews=data=>{
      return request({
        method:'POST',
        url:'/system/news/addNews',
        data,
        headers: {
          'CustomRequestType': 'application/json;charset=UTF-8'
        }
      
      })
      }
      //删除新闻
      
      export const delNews=data=>{
        return request({
          method:'POST',
          url:'/system/news/deleteNews',
          data,
          // headers: {
          //   'CustomRequestType': 'application/json;charset=UTF-8'
          // }
        
        })
        }
      //编辑新闻
      export const editNews=data=>{
        return request({
          method:'POST',
          url:'/system/news/updateNews',
          data,
           headers: {
            'CustomRequestType': 'application/json;charset=UTF-8'
          }
        })
        }
        

//修改文章的发布状态
export const changeStatus=data=>{
  return request({
    method:'POST',
    url:'/system/news/updatePublishNews',
    data
  })
  }